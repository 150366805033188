import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Construction, ArrowLeft, Car } from 'lucide-react';
import Header from '../components/general/Header';
import Footer from '../components/general/Footer';

const UnderConstructionPage = () => {
  const [scrollY, setScrollY] = useState(0);
  
  // For scrolling effect
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white font-sans">
      {/* Use Header with scrollY */}
      <Header scrollY={scrollY} />

      {/* Main Content */}
      <section className="pt-32 pb-24 flex flex-col justify-center items-center min-h-[70vh] relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900/20 to-gray-900 z-0"></div>
        
        {/* Circuit board background pattern */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute h-full w-full">
            {[...Array(20)].map((_, i) => (
              <div 
                key={i} 
                className="absolute bg-blue-500" 
                style={{
                  height: '1px',
                  width: `${Math.random() * 20 + 5}%`,
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 80}%`,
                  opacity: Math.random() * 0.5 + 0.3,
                  animation: `pulse ${Math.random() * 4 + 3}s infinite alternate`
                }}
              />
            ))}
            {[...Array(20)].map((_, i) => (
              <div 
                key={i+20} 
                className="absolute bg-blue-500" 
                style={{
                  width: '1px',
                  height: `${Math.random() * 20 + 5}%`,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 80}%`,
                  opacity: Math.random() * 0.5 + 0.3,
                  animation: `pulse ${Math.random() * 4 + 3}s infinite alternate`
                }}
              />
            ))}
          </div>
        </div>
        
        {/* Content */}
        <div className="container mx-auto px-4 relative z-10 text-center">
          <div className="animate-bounce mb-8 flex justify-center">
            <Construction size={80} className="text-yellow-400" />
          </div>
          <h1 className="text-4xl md:text-6xl font-bold mb-4">Under Construction</h1>
          <div className="h-1 w-24 bg-blue-500 mx-auto mb-8"></div>
          <p className="text-xl md:text-2xl text-gray-300 max-w-2xl mx-auto mb-12">
            We're working hard to improve this page and will be back soon with a better experience.
          </p>
          <Link 
            to="/" 
            className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-3 rounded-md transition shadow-lg inline-flex items-center"
          >
            <ArrowLeft className="mr-2" />
            Back to Home
          </Link>
        </div>
      </section>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default UnderConstructionPage;