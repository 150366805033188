import React from 'react';

const SectionHeading = ({
  title,
  subtitle,
  badge,
  center = true,
  className = ""
}) => {
  return (
    <div className={`mb-16 ${center ? 'text-center' : ''} ${className}`}>
      {badge && (
        <div className="inline-block bg-blue-600/20 border border-blue-500/30 rounded-lg px-3 py-1 text-sm text-blue-400 font-medium mb-4">
          {badge}
        </div>
      )}
      <h2 className="text-3xl md:text-4xl font-bold mb-4">{title}</h2>
      {subtitle && (
        <p className="text-xl text-gray-400 max-w-3xl mx-auto">
          {subtitle}
        </p>
      )}
    </div>
  );
};

export default SectionHeading; 