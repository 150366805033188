import React from 'react';
import { AlertTriangle, Cpu, Wrench } from 'lucide-react';
import Section from '../general/Section';
import SectionHeading from '../general/SectionHeading';

const DiagnosticTools = () => {
  return (
    <Section className="bg-gradient-to-b from-gray-900 to-gray-800">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900 to-blue-900/20 opacity-50"></div>
      
      <SectionHeading
        badge="ADVANCED DIAGNOSTICS"
        title="Keep Your Vehicle in Perfect Health"
        subtitle="Our comprehensive diagnostic tools help you identify and resolve issues before they become serious problems."
      />
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div>
          <h3 className="text-2xl font-bold mb-4 text-blue-400">Powerful Diagnostic Features</h3>
          <ul className="space-y-4">
            <li className="flex items-start gap-3">
              <div className="bg-blue-500/20 p-2 rounded-full mt-1">
                <AlertTriangle className="text-blue-400 w-5 h-5" />
              </div>
              <div>
                <div className="font-medium text-lg">Early Warning System</div>
                <p className="text-gray-400">Identifies potential issues before they cause breakdowns or expensive repairs.</p>
              </div>
            </li>
            <li className="flex items-start gap-3">
              <div className="bg-blue-500/20 p-2 rounded-full mt-1">
                <Cpu className="text-blue-400 w-5 h-5" />
              </div>
              <div>
                <div className="font-medium text-lg">OBD Integration</div>
                <p className="text-gray-400">Connects directly to your vehicle's onboard diagnostic system for accurate readings.</p>
              </div>
            </li>
            <li className="flex items-start gap-3">
              <div className="bg-blue-500/20 p-2 rounded-full mt-1">
                <Wrench className="text-blue-400 w-5 h-5" />
              </div>
              <div>
                <div className="font-medium text-lg">AI Mechanic Assistant</div>
                <p className="text-gray-400">Get expert advice and step-by-step guidance for common car problems.</p>
              </div>
            </li>
          </ul>
        </div>
        
        <div>
          <div className="relative rounded-xl overflow-hidden shadow-2xl">
            <img 
              src={`${process.env.PUBLIC_URL}/assets/diagnosis.jpg`} 
              alt="Advanced Diagnostic Tools"
              className="w-full h-auto rounded-lg"
            />
          </div>
        </div>
      </div>
      
      <div className="mt-16">
        <div className="relative rounded-xl overflow-hidden">
          <div className="absolute -inset-1 bg-gradient-to-r from-blue-600/30 via-purple-600/30 to-blue-600/30 blur-lg opacity-50"></div>
          <div className="relative rounded-xl overflow-hidden shadow-2xl">
            <img 
              src={`${process.env.PUBLIC_URL}/assets/AI_mechanic.jpg`} 
              alt="AI Mechanic Assistant Interface"
              className="w-full h-auto"
            />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default DiagnosticTools; 