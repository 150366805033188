import React from 'react';

const PageContainer = ({ 
  children, 
  className = "", 
  fullWidth = false 
}) => {
  return (
    <div className={`relative ${!fullWidth ? 'container mx-auto px-4' : ''} ${className}`}>
      {children}
    </div>
  );
};

export default PageContainer; 