import Cookies from 'js-cookie';

const CART_STORAGE_KEY = 'car_monitor_cart';
const CART_EXPIRY_DAYS = 7;

/**
 * Load cart data from storage (cookies or localStorage)
 * @returns {Array} Cart items array
 */
export const loadCartFromStorage = () => {
  try {
    // Try to load from cookies first
    const cookieCart = Cookies.get(CART_STORAGE_KEY);
    
    if (cookieCart) {
      console.log('Loading cart from cookies:', cookieCart);
      return JSON.parse(cookieCart);
    }
    
    // If not in cookies, try localStorage
    const localStorageCart = localStorage.getItem(CART_STORAGE_KEY);
    if (localStorageCart) {
      console.log('Loading cart from localStorage');
      return JSON.parse(localStorageCart);
    }
    
    return [];
  } catch (error) {
    console.error('Error loading cart from storage:', error);
    return [];
  }
};

/**
 * Save cart data to storage (cookies and localStorage)
 * @param {Array} cart Cart items array
 */
export const saveCartToStorage = (cart) => {
  try {
    const cartString = JSON.stringify(cart);
    
    // Save to cookies
    Cookies.set(CART_STORAGE_KEY, cartString, { expires: CART_EXPIRY_DAYS });
    
    // Also save to localStorage as backup
    localStorage.setItem(CART_STORAGE_KEY, cartString);
  } catch (error) {
    console.error('Error saving cart to storage:', error);
  }
};

/**
 * Clear cart data from storage
 */
export const clearCartFromStorage = () => {
  try {
    Cookies.remove(CART_STORAGE_KEY);
    localStorage.removeItem(CART_STORAGE_KEY);
  } catch (error) {
    console.error('Error clearing cart from storage:', error);
  }
};

/**
 * Calculate total price of items in cart
 * @param {Array} cart Cart items array
 * @returns {number} Total price
 */
export const calculateCartTotal = (cart) => {
  return cart.reduce((total, item) => {
    const price = item.discountPrice || item.price || 0;
    const quantity = item.quantity || 1;
    return total + (price * quantity);
  }, 0);
};

/**
 * Get total number of items in cart
 * @param {Array} cart Cart items array
 * @returns {number} Total item count
 */
export const getCartItemCount = (cart) => {
  return cart.reduce((count, item) => {
    return count + (item.quantity || 1);
  }, 0);
};
