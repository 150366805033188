import { supabase } from '../utils/supabase';

// User authentication functions
export const loginUser = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password
  });
  
  if (error) throw error;
  return data;
};

export const registerUser = async (email, password, userData = {}) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      data: userData
    }
  });
  
  if (error) throw error;
  return data;
};

export const logoutUser = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
  return true;
};

export const getUserData = async (userId) => {
  // Get user profile data from the profiles table
  const { data, error } = await supabase
    .from('profiles')
    .select('*')
    .eq('id', userId)
    .single();
  
  if (error) throw error;
  return data;
};

// User data functions

// Get complete user profile with all related data
export const getUserProfile = async (userId = null) => {
  try {
    // If no userId provided, get current user
    if (!userId) {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('No authenticated user found');
      userId = user.id;
    }
    
    console.log('Fetching profile for user ID:', userId);
    
    // Query using auth_id directly since user_id doesn't exist
    const { data: profile, error: profileError } = await supabase
      .from('Users')
      .select('*')
      .eq('auth_id', userId)
      .single();
      
    if (profileError) {
      console.error('Error fetching with auth_id:', profileError);
      
      // Try with email as fallback
      const { data: { user } } = await supabase.auth.getUser();
      if (user?.email) {
        const { data: profileEmail, error: profileEmailError } = await supabase
          .from('Users')
          .select('*')
          .eq('email', user.email)
          .single();
          
        if (profileEmailError) {
          console.error('Error fetching with email:', profileEmailError);
          // Fall back to demo user
          return getDemoUserProfile();
        }
        
        if (profileEmail) {
          return buildFullProfile(profileEmail);
        }
      }
      
      // Fall back to demo user
      return getDemoUserProfile();
    }
    
    if (profile) {
      return buildFullProfile(profile);
    }
    
    // If no profile is found, try to get a demo user
    console.log('No profile found, using demo profile');
    return getDemoUserProfile();
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return getDemoUserProfile();
  }
};

// Helper function to build the full profile with related data
const buildFullProfile = async (profile) => {
  try {
    // Get addresses
    const { data: addresses, error: addressesError } = await supabase
      .from('Addresses')
      .select('*')
      .eq('userId', profile.id);
      
    if (addressesError) console.error('Error fetching addresses:', addressesError);
    
    // Get payment methods
    const { data: paymentMethods, error: paymentMethodsError } = await supabase
      .from('PaymentMethods')
      .select('*')
      .eq('userId', profile.id);
      
    if (paymentMethodsError) console.error('Error fetching payment methods:', paymentMethodsError);
    
    // Get user orders
    const { data: orders, error: ordersError } = await supabase
      .from('Orders')
      .select('*')
      .eq('userId', profile.id)
      .order('date', { ascending: false });
      
    if (ordersError) console.error('Error fetching orders:', ordersError);
    
    // Fetch order items separately if orders exist
    let orderWithItems = [];
    if (orders && orders.length > 0) {
      // Create an array of order IDs
      const orderIds = orders.map(order => order.id);
      
      // Fetch all order items for these orders
      const { data: orderItems, error: orderItemsError } = await supabase
        .from('OrderItems')
        .select('*')
        .in('orderId', orderIds);
        
      if (orderItemsError) {
        console.error('Error fetching order items:', orderItemsError);
      } else {
        // Map order items to their respective orders
        orderWithItems = orders.map(order => ({
          ...order,
          items: orderItems.filter(item => item.orderId === order.id) || []
        }));
      }
    }
    
    // Get notifications
    const { data: notifications, error: notificationsError } = await supabase
      .from('Notifications')
      .select('*')
      .eq('userId', profile.id)
      .order('date', { ascending: false });
      
    if (notificationsError) console.error('Error fetching notifications:', notificationsError);
    
    // Get wishlist
    const { data: wishlist, error: wishlistError } = await supabase
      .from('WishlistItems')
      .select(`
        *,
        product:Products(*)
      `)
      .eq('userId', profile.id);
      
    if (wishlistError) console.error('Error fetching wishlist:', wishlistError);
    
    // Format wishlist items
    const formattedWishlist = wishlist ? wishlist.map(item => ({
      id: item.id,
      productId: item.product?.id,
      name: item.product?.name || 'Unknown Product',
      price: item.product?.discountPrice || item.product?.price || 0,
      regularPrice: item.product?.price || 0,
      image: item.product?.image || '',
      inStock: true
    })) : [];
    
    return {
      ...profile,
      addresses: addresses || [],
      paymentMethods: paymentMethods || [],
      orders: orderWithItems.length > 0 ? orderWithItems : (orders || []),
      notifications: notifications || [],
      wishlist: formattedWishlist || []
    };
  } catch (error) {
    console.error('Error building full profile:', error);
    throw error;
  }
};

// Helper function to get a demo profile
const getDemoUserProfile = async () => {
  try {
    // Create a mock user profile since we can't get a real one
    console.log('Creating mock user profile');
    return {
      id: 'demo-user-id',
      name: 'Alex Johnson',
      email: 'alex.johnson@example.com',
      phone: '555-123-4567',
      created_at: new Date().toISOString(),
      addresses: [],
      paymentMethods: [],
      orders: [],
      notifications: [
        {
          id: 'notif-1',
          title: 'Welcome to Our Store',
          message: 'Thank you for creating an account with us!',
          date: new Date().toISOString(),
          read: false
        }
      ],
      wishlist: []
    };
  } catch (error) {
    console.error('Error creating mock user profile:', error);
    // Return a minimal profile
    return {
      id: 'demo-user-id',
      name: 'Demo User',
      email: 'demo@example.com',
      addresses: [],
      paymentMethods: [],
      orders: [],
      notifications: [],
      wishlist: []
    };
  }
};

// Update user data
export const updateUserProfile = async (userData) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('No authenticated user found');
  
  const { data, error } = await supabase
    .from('Users')
    .update({
      name: userData.name,
      // Add other fields as needed
    })
    .eq('auth_id', user.id)
    .select();
  
  if (error) throw error;
  return data;
};

// Change password
export const changePassword = async (currentPassword, newPassword) => {
  // First verify the current password
  const { error: verifyError } = await supabase.auth.signInWithPassword({
    email: supabase.auth.getUser().then(({ data }) => data.user.email),
    password: currentPassword
  });
  
  if (verifyError) throw new Error('Current password is incorrect');
  
  // Then update password
  const { error } = await supabase.auth.updateUser({
    password: newPassword
  });
  
  if (error) throw error;
  return true;
};

// Add address
export const addAddress = async (addressData) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('No authenticated user found');
  
  // If this is the default address, unset existing defaults
  if (addressData.default) {
    await supabase
      .from('Addresses')
      .update({ default: false })
      .eq('userId', user.id);
  }
  
  const { data, error } = await supabase
    .from('Addresses')
    .insert({
      ...addressData,
      userId: user.id
    })
    .select();
  
  if (error) throw error;
  return data;
};

// Update address
export const updateAddress = async (addressId, addressData) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('No authenticated user found');
  
  // If this is the default address, unset existing defaults
  if (addressData.default) {
    await supabase
      .from('Addresses')
      .update({ default: false })
      .eq('userId', user.id)
      .neq('id', addressId);
  }
  
  const { data, error } = await supabase
    .from('Addresses')
    .update(addressData)
    .eq('id', addressId)
    .select();
  
  if (error) throw error;
  return data;
};

// Delete address
export const deleteAddress = async (addressId) => {
  const { error } = await supabase
    .from('Addresses')
    .delete()
    .eq('id', addressId);
  
  if (error) throw error;
  return true;
};

// Add or remove wishlist item
export const toggleWishlistItem = async (productId) => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('No authenticated user found');
  
  // Check if item exists in wishlist
  const { data: existing } = await supabase
    .from('WishlistItems')
    .select('id')
    .eq('userId', user.id)
    .eq('productId', productId)
    .single();
  
  if (existing) {
    // Remove from wishlist
    const { error } = await supabase
      .from('WishlistItems')
      .delete()
      .eq('id', existing.id);
    
    if (error) throw error;
    return { added: false };
  } else {
    // Add to wishlist
    const { data, error } = await supabase
      .from('WishlistItems')
      .insert({
        userId: user.id,
        productId
      })
      .select();
    
    if (error) throw error;
    return { added: true, item: data[0] };
  }
};

// Product and category API functions

// Get all categories
export const getCategories = async () => {
  const { data, error } = await supabase
    .from('Categories')
    .select('*')
    .order('name');
    
  if (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }
  
  return data || [];
};

// Get all products with optional pagination
export const getProducts = async ({ page = 1, limit = 20, sortBy = 'createdAt', order = 'desc' } = {}) => {
  const startIndex = (page - 1) * limit;
  
  const { data, error, count } = await supabase
    .from('Products')
    .select('*', { count: 'exact' })
    .order(sortBy, { ascending: order === 'asc' })
    .range(startIndex, startIndex + limit - 1);
    
  if (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
  
  return {
    products: data || [],
    total: count || 0,
    currentPage: page,
    totalPages: Math.ceil((count || 0) / limit)
  };
};

// Get a specific product by ID
export const getProductById = async (productId) => {
  try {
    // First get the product
    const { data: product, error } = await supabase
      .from('Products')
      .select('*')
      .eq('id', productId)
      .single();
      
    if (error) throw error;
    
    if (!product) {
      return null;
    }
    
    // Now get the reviews for this product
    const { data: reviews, error: reviewsError } = await supabase
      .from('Reviews')
      .select('*')
      .eq('productId', productId)
      .order('date', { ascending: false });
      
    if (reviewsError) throw reviewsError;
    
    // Get feature icon mappings from the database
    const { data: featureIcons, error: featureIconsError } = await supabase
      .from('FeatureIcons')
      .select('featureName, iconName, description');
      
    if (featureIconsError) {
      console.warn('Could not fetch feature icons:', featureIconsError);
      // Continue without feature icons if there's an error (table might not exist yet)
    }
    
    // Define colors for rotation
    const colors = ['blue', 'red', 'green', 'purple', 'yellow', 'cyan'];
    
    // Create feature mappings if we have them from the database
    const featureIconMap = {};
    const keywordIconMap = {};
    
    if (featureIcons && featureIcons.length > 0) {
      featureIcons.forEach(fi => {
        // Complete feature entries have descriptions
        if (fi.description) {
          featureIconMap[fi.featureName] = { 
            icon: fi.iconName, 
            description: fi.description
          };
        } 
        // Keyword entries don't have descriptions
        else {
          keywordIconMap[fi.featureName] = fi.iconName;
        }
      });
    }
    
    // Format features if they are stored as strings
    let formattedFeatures = product.features || [];
    
    // Check if features is an array of strings (text[] column)
    if (Array.isArray(formattedFeatures) && formattedFeatures.length > 0) {
      // If the features are already objects with all properties, just add color
      if (typeof formattedFeatures[0] === 'object' && formattedFeatures[0].icon && formattedFeatures[0].title) {
        formattedFeatures = formattedFeatures.map((feature, index) => ({
          ...feature,
          color: colors[index % colors.length]
        }));
      } 
      // If features are strings, transform them to objects
      else if (typeof formattedFeatures[0] === 'string') {
        formattedFeatures = formattedFeatures.map((feature, index) => {
          let iconName = 'Check';
          let description = `This ${feature.toLowerCase()} feature enhances your vehicle monitoring experience.`;
          
          // Try to find a direct match in our mapping
          if (featureIconMap[feature]) {
            iconName = featureIconMap[feature].icon;
            description = featureIconMap[feature].description;
          }
          // Try to find a keyword match
          else {
            const matchedKeyword = Object.keys(keywordIconMap).find(key => feature.includes(key));
            if (matchedKeyword) {
              iconName = keywordIconMap[matchedKeyword];
            }
          }
          
          return {
            icon: iconName,
            title: feature,
            description: description,
            color: colors[index % colors.length]
          };
        });
      }
    }
    
    // Ensure specifications is an object
    const specifications = product.specifications || {};
    
    // Return the product with reviews
    return {
      ...product,
      features: formattedFeatures,
      specifications,
      reviews: reviews || [],
      images: product.images && product.images.length > 0 ? product.images : [product.image]
    };
  } catch (error) {
    console.error(`Error fetching product with ID ${productId}:`, error);
    throw error;
  }
};

// Get products by category
export const getProductsByCategory = async (categoryId, { page = 1, limit = 20 } = {}) => {
  const startIndex = (page - 1) * limit;
  
  // If categoryId is 'all', fetch all products
  if (categoryId === 'all') {
    return getProducts({ page, limit });
  }
  
  const { data, error, count } = await supabase
    .from('Products')
    .select('*', { count: 'exact' })
    .eq('categoryId', categoryId)
    .range(startIndex, startIndex + limit - 1);
    
  if (error) {
    console.error(`Error fetching products for category ${categoryId}:`, error);
    throw error;
  }
  
  return {
    products: data || [],
    total: count || 0,
    currentPage: page,
    totalPages: Math.ceil((count || 0) / limit)
  };
};

// Additional helper functions

// Search products by term
export const searchProducts = async (searchTerm, { page = 1, limit = 20 } = {}) => {
  const startIndex = (page - 1) * limit;
  
  const { data, error, count } = await supabase
    .from('Products')
    .select('*', { count: 'exact' })
    .ilike('name', `%${searchTerm}%`)
    .range(startIndex, startIndex + limit - 1);
    
  if (error) {
    console.error('Error searching products:', error);
    throw error;
  }
  
  return {
    products: data || [],
    total: count || 0,
    currentPage: page,
    totalPages: Math.ceil((count || 0) / limit)
  };
};