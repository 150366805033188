import React from 'react';
import { Car } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 pt-16 pb-8 border-t border-gray-800 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900/50 to-gray-900/90"></div>
      <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-blue-500/30 to-transparent"></div>
            
      <div className="container mx-auto px-4 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
          <div>
            <div className="flex items-center space-x-2 mb-6">
              <div className="relative">
                <div className="absolute inset-0 bg-blue-500/30 rounded-full blur"></div>
                <Car className="h-7 w-7 text-blue-400 relative" />
              </div>
              <span className="text-2xl font-bold text-white bg-clip-text bg-gradient-to-r from-blue-400 to-blue-300">CarNebula</span>
            </div>
            <p className="text-gray-400 mb-6">
              Advanced vehicle display systems with cutting-edge monitoring and diagnostics for the modern driver.
            </p>
            <div className="flex space-x-4">
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="group text-gray-400 hover:text-white transition-colors duration-300 bg-gray-800/50 p-2 rounded-full hover:bg-blue-500/20">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                </svg>
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="group text-gray-400 hover:text-white transition-colors duration-300 bg-gray-800/50 p-2 rounded-full hover:bg-blue-500/20">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zM12 0C8.741 0 8.333.014 7.053.072 2.695.272.273 2.69.073 7.052.014 8.333 0 8.741 0 12c0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98C8.333 23.986 8.741 24 12 24c3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98C15.668.014 15.259 0 12 0z" />
                  <path d="M12 5.838a6.162 6.162 0 100 12.324 6.162 6.162 0 000-12.324zM12 16a4 4 0 110-8 4 4 0 010 8z" />
                </svg>
              </a>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="group text-gray-400 hover:text-white transition-colors duration-300 bg-gray-800/50 p-2 rounded-full hover:bg-blue-500/20">
                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
                </svg>
              </a>
            </div>
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4 text-white">Products</h3>
            <ul className="space-y-3">
              <li>
                <a href="/shop" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">New Display Units</span>
                </a>
              </li>
              <li>
                <a href="/shop" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">Display Upgrades</span>
                </a>
              </li>
              <li>
                <a href="/shop" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">Business Solutions</span>
                </a>
              </li>
              <li>
                <a href="/shop" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">Accessories</span>
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4 text-white">Resources</h3>
            <ul className="space-y-3">
              <li>
                <a href="/support" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">Documentation</span>
                </a>
              </li>
              <li>
                <a href="/support" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">Installation Guides</span>
                </a>
              </li>
              <li>
                <a href="/support" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">API Documentation</span>
                </a>
              </li>
              <li>
                <a href="/support" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">FAQs</span>
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-lg font-bold mb-4 text-white">Company</h3>
            <ul className="space-y-3">
              <li>
                <a href="/about" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">About Us</span>
                </a>
              </li>
              <li>
                <a href="/about" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">Careers</span>
                </a>
              </li>
              <li>
                <a href="/support" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">Contact Us</span>
                </a>
              </li>
              <li>
                <a href="/about" className="text-gray-400 hover:text-blue-400 transition-colors duration-300 flex items-center group">
                  <span className="absolute w-0 h-0.5 bg-blue-400 group-hover:w-4 transition-all duration-300 opacity-0 group-hover:opacity-100 mr-2"></span>
                  <span className="group-hover:translate-x-5 transition-transform duration-300">Partnerships</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center">
          <div className="text-gray-500 mb-4 md:mb-0">
            © 2025 CarNebula. All rights reserved.
          </div>
          <div className="flex flex-wrap gap-4">
            <a href="/support" className="text-gray-500 hover:text-white transition-colors duration-300 text-sm">Privacy Policy</a>
            <a href="/support" className="text-gray-500 hover:text-white transition-colors duration-300 text-sm">Terms of Service</a>
            <a href="/support" className="text-gray-500 hover:text-white transition-colors duration-300 text-sm">Cookie Policy</a>
            <a href="/support" className="text-gray-500 hover:text-white transition-colors duration-300 text-sm">Accessibility</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 