import React from 'react';
import { Car } from 'lucide-react';

const Testimonials = () => {
  return (
    <section id="testimonials" className="py-16 md:py-24 bg-gradient-to-b from-gray-900 to-gray-800/80 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900/70 to-gray-800/70"></div>
      
      {/* Decorative backgrounds */}
      <div className="absolute top-0 left-0 right-0 h-40 bg-gradient-to-b from-blue-900/10 to-transparent"></div>
      <div className="absolute -left-20 top-1/3 w-80 h-80 bg-blue-500/10 rounded-full filter blur-3xl"></div>
      <div className="absolute -right-20 bottom-1/3 w-80 h-80 bg-purple-500/10 rounded-full filter blur-3xl"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <div className="inline-block bg-blue-600/20 border border-blue-500/30 rounded-lg px-3 py-1 text-sm text-blue-400 font-medium mb-4">
            FROM OUR CUSTOMERS
          </div>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">What Users Say</h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Don't just take our word for it - hear from drivers who use our display systems every day.
          </p>
        </div>
        
        <div className="relative">
          <div className="max-w-5xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="group relative transition-all duration-500 hover:z-10 hover:scale-105">
                <div className="absolute -inset-2 bg-gradient-to-r from-blue-600/20 via-blue-600/0 to-blue-600/20 rounded-xl blur opacity-0 group-hover:opacity-100 animate-pulse transition-opacity duration-500"></div>
                <div className="relative bg-gray-800/50 rounded-xl p-6 border border-gray-700 group-hover:border-blue-500/30 transition-all duration-300 shadow-lg">
                  {/* Decorative quote */}
                  <div className="absolute -top-4 -left-2 text-4xl text-blue-500/20 font-serif">"</div>
                  <div className="absolute -bottom-4 -right-2 text-4xl text-blue-500/20 font-serif">"</div>
                  
                  <div className="flex items-center gap-2 mb-4">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <div key={star} className="text-yellow-500 transition-transform duration-300 group-hover:scale-110">★</div>
                    ))}
                  </div>
                  
                  <p className="text-gray-300 mb-6 relative">
                    <span className="absolute -left-1 top-0 h-full w-1 bg-blue-500/30 rounded-full"></span>
                    "The display upgrade transformed my driving experience. The diagnostic features saved me thousands in potential repair costs by catching issues early."
                  </p>
                  
                  <div className="flex items-center gap-4">
                    <div className="relative">
                      <div className="absolute inset-0 bg-blue-500/30 rounded-full blur group-hover:bg-blue-500/50 transition-colors duration-300"></div>
                      <div className="w-12 h-12 bg-gradient-to-br from-blue-600 to-blue-400 rounded-full flex items-center justify-center relative">
                        <span className="font-bold">AK</span>
                      </div>
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-blue-400 transition-colors duration-300">Ahmed K.</div>
                      <div className="text-sm text-gray-400 flex items-center">
                        <Car className="w-3 h-3 mr-1" />
                        <span>Mitsubishi Owner</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="group relative transition-all duration-500 hover:z-10 hover:scale-105">
                <div className="absolute -inset-2 bg-gradient-to-r from-green-600/20 via-green-600/0 to-green-600/20 rounded-xl blur opacity-0 group-hover:opacity-100 animate-pulse transition-opacity duration-500"></div>
                <div className="relative bg-gray-800/50 rounded-xl p-6 border border-gray-700 group-hover:border-green-500/30 transition-all duration-300 shadow-lg">
                  {/* Decorative quote */}
                  <div className="absolute -top-4 -left-2 text-4xl text-green-500/20 font-serif">"</div>
                  <div className="absolute -bottom-4 -right-2 text-4xl text-green-500/20 font-serif">"</div>
                  
                  <div className="flex items-center gap-2 mb-4">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <div key={star} className="text-yellow-500 transition-transform duration-300 group-hover:scale-110">★</div>
                    ))}
                  </div>
                  
                  <p className="text-gray-300 mb-6 relative">
                    <span className="absolute -left-1 top-0 h-full w-1 bg-green-500/30 rounded-full"></span>
                    "As a car enthusiast, I love the detailed performance metrics on my new display unit. The acceleration and speed tracking features are incredible for tracking my improvements."
                  </p>
                  
                  <div className="flex items-center gap-4">
                    <div className="relative">
                      <div className="absolute inset-0 bg-green-500/30 rounded-full blur group-hover:bg-green-500/50 transition-colors duration-300"></div>
                      <div className="w-12 h-12 bg-gradient-to-br from-green-600 to-green-400 rounded-full flex items-center justify-center relative">
                        <span className="font-bold">SL</span>
                      </div>
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-green-400 transition-colors duration-300">Sara L.</div>
                      <div className="text-sm text-gray-400 flex items-center">
                        <Car className="w-3 h-3 mr-1" />
                        <span>Honda Civic Owner</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="group relative transition-all duration-500 hover:z-10 hover:scale-105">
                <div className="absolute -inset-2 bg-gradient-to-r from-purple-600/20 via-purple-600/0 to-purple-600/20 rounded-xl blur opacity-0 group-hover:opacity-100 animate-pulse transition-opacity duration-500"></div>
                <div className="relative bg-gray-800/50 rounded-xl p-6 border border-gray-700 group-hover:border-purple-500/30 transition-all duration-300 shadow-lg">
                  {/* Decorative quote */}
                  <div className="absolute -top-4 -left-2 text-4xl text-purple-500/20 font-serif">"</div>
                  <div className="absolute -bottom-4 -right-2 text-4xl text-purple-500/20 font-serif">"</div>
                  
                  <div className="flex items-center gap-2 mb-4">
                    {[1, 2, 3, 4, 5].map((star) => (
                      <div key={star} className="text-yellow-500 transition-transform duration-300 group-hover:scale-110">★</div>
                    ))}
                  </div>
                  
                  <p className="text-gray-300 mb-6 relative">
                    <span className="absolute -left-1 top-0 h-full w-1 bg-purple-500/30 rounded-full"></span>
                    "Integrating this display technology across our fleet has been a game-changer. The diagnostics system gives us early warnings of issues and has dramatically reduced our maintenance costs."
                  </p>
                  
                  <div className="flex items-center gap-4">
                    <div className="relative">
                      <div className="absolute inset-0 bg-purple-500/30 rounded-full blur group-hover:bg-purple-500/50 transition-colors duration-300"></div>
                      <div className="w-12 h-12 bg-gradient-to-br from-purple-600 to-purple-400 rounded-full flex items-center justify-center relative">
                        <span className="font-bold">MT</span>
                      </div>
                    </div>
                    <div>
                      <div className="font-medium group-hover:text-purple-400 transition-colors duration-300">Mohammed T.</div>
                      <div className="text-sm text-gray-400 flex items-center">
                        <Car className="w-3 h-3 mr-1" />
                        <span>Vehicle Fleet Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="mt-16 text-center">
              <button className="group relative px-8 py-4 bg-gradient-to-r from-blue-600/20 to-purple-600/20 rounded-lg overflow-hidden transition-all duration-300 hover:from-blue-600/30 hover:to-purple-600/30 border border-blue-500/30 hover:border-purple-500/50">
                <div className="absolute inset-0 w-0 group-hover:w-full transition-all duration-700 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 blur-sm"></div>
                <span className="relative text-blue-400 group-hover:text-blue-300 transition-colors duration-300">Read More Success Stories</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {/* Stats Section */}
      <div className="mt-24 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-gray-900/0 to-blue-900/20"></div>
        <div className="relative container mx-auto px-4">
          <div className="text-center mb-16">
            <h3 className="text-2xl font-bold mb-2">By The Numbers</h3>
            <p className="text-gray-400 max-w-2xl mx-auto">The proven track record of our display systems speaks for itself</p>
          </div>
          
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="relative group">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-600 to-purple-600 rounded-lg blur opacity-25 group-hover:opacity-50 transition duration-1000"></div>
              <div className="relative bg-gray-900 rounded-lg p-6 text-center">
                <div className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-blue-400 to-blue-300 inline-block text-transparent bg-clip-text mb-2 group-hover:scale-110 transition-transform duration-300">98%</div>
                <div className="text-lg text-gray-300">Customer Satisfaction</div>
                <div className="text-sm text-gray-500 mt-2">Based on over 15,000 reviews</div>
              </div>
            </div>
            
            <div className="relative group">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25 group-hover:opacity-50 transition duration-1000"></div>
              <div className="relative bg-gray-900 rounded-lg p-6 text-center">
                <div className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-purple-400 to-pink-300 inline-block text-transparent bg-clip-text mb-2 group-hover:scale-110 transition-transform duration-300">45%</div>
                <div className="text-lg text-gray-300">Maintenance Cost Reduction</div>
                <div className="text-sm text-gray-500 mt-2">Average savings reported by fleet customers</div>
              </div>
            </div>
            
            <div className="relative group">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-green-600 to-blue-600 rounded-lg blur opacity-25 group-hover:opacity-50 transition duration-1000"></div>
              <div className="relative bg-gray-900 rounded-lg p-6 text-center">
                <div className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-green-400 to-blue-300 inline-block text-transparent bg-clip-text mb-2 group-hover:scale-110 transition-transform duration-300">100k+</div>
                <div className="text-lg text-gray-300">Units Sold</div>
                <div className="text-sm text-gray-500 mt-2">In over 30 countries worldwide</div>
              </div>
            </div>
            
            <div className="relative group">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-orange-600 to-yellow-600 rounded-lg blur opacity-25 group-hover:opacity-50 transition duration-1000"></div>
              <div className="relative bg-gray-900 rounded-lg p-6 text-center">
                <div className="text-4xl md:text-5xl font-bold bg-gradient-to-r from-orange-400 to-yellow-300 inline-block text-transparent bg-clip-text mb-2 group-hover:scale-110 transition-transform duration-300">2.5M+</div>
                <div className="text-lg text-gray-300">Hours of Driving</div>
                <div className="text-sm text-gray-500 mt-2">Data collected and analyzed</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials; 