import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainLandingPage from './pages/MainLandingPage';
import { AppProvider } from './context/AppContext';
import UnderConstructionPage from './pages/UnderConstructionPage';

// Import all pages but we'll redirect them to UnderConstructionPage
import AboutUsPage from './pages/AboutUsPage';
import CartCheckoutPage from './pages/CartCheckoutPage';
import FeaturesPage from './pages/FeaturesPage';
import ProductDetailPage from './pages/ProductDetailPage';
import ShopPage from './pages/ShopPage';
import SupportPage from './pages/SupportPage';
import UserAccountPage from './pages/UserAccountPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import AdminProductsPage from './pages/admin/AdminProductsPage';
import AdminCategoriesPage from './pages/admin/AdminCategoriesPage';

function App() {
  // Change this to false when you want to restore full functionality
  const underConstruction = true;
  
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          {/* Home page is always accessible */}
          <Route path="/" element={<MainLandingPage />} />
          
          {/* All other routes will conditionally render based on underConstruction state */}
          <Route path="/about" element={underConstruction ? <UnderConstructionPage /> : <AboutUsPage />} />
          <Route path="/cart" element={underConstruction ? <UnderConstructionPage /> : <CartCheckoutPage />} />
          <Route path="/features" element={underConstruction ? <UnderConstructionPage /> : <FeaturesPage />} />
          <Route path="/product/:id" element={underConstruction ? <UnderConstructionPage /> : <ProductDetailPage />} />
          <Route path="/shop" element={underConstruction ? <UnderConstructionPage /> : <ShopPage />} />
          <Route path="/support" element={underConstruction ? <UnderConstructionPage /> : <SupportPage />} />
          <Route path="/account" element={underConstruction ? <UnderConstructionPage /> : <UserAccountPage />} />
          <Route path="/signin" element={underConstruction ? <UnderConstructionPage /> : <SignInPage />} />
          <Route path="/signup" element={underConstruction ? <UnderConstructionPage /> : <SignUpPage />} />
          <Route path="/admin/products" element={underConstruction ? <UnderConstructionPage /> : <AdminProductsPage />} />
          <Route path="/admin/categories" element={underConstruction ? <UnderConstructionPage /> : <AdminCategoriesPage />} />
          
          {/* Catch all other routes */}
          <Route path="*" element={<UnderConstructionPage />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;