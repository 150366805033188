import React from 'react';
import Section from '../general/Section';
import Button from '../general/Button';

const CTA = () => {
  return (
    <Section className="relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900/70 to-purple-900/70"></div>
      
      {/* Animated particles */}
      <div className="absolute inset-0">
        {[...Array(30)].map((_, i) => (
          <div
            key={i}
            className="absolute rounded-full bg-white opacity-30"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 4 + 1}px`,
              height: `${Math.random() * 4 + 1}px`,
              animation: `float ${Math.random() * 10 + 10}s linear infinite`,
              animationDelay: `${Math.random() * 10}s`
            }}
          />
        ))}
      </div>
      
      {/* Glowing orb */}
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-96 h-96">
        <div className="absolute inset-0 bg-blue-500/20 rounded-full blur-3xl"></div>
      </div>
      
      <div className="max-w-4xl mx-auto text-center">
        <h2 className="text-3xl md:text-5xl font-bold mb-6 text-white leading-tight">
          Transform Your <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">Driving Experience</span> Today
        </h2>
        <p className="text-xl text-gray-300 mb-10 max-w-3xl mx-auto">
          Choose the solution that works best for you and join thousands of satisfied drivers who have already upgraded their vehicles with our advanced display technology.
        </p>
        
        <div className="flex flex-wrap justify-center gap-4 md:gap-6">
          <Button
            variant="primary"
            size="lg"
            href="/shop"
            className="group transform hover:-translate-y-1"
          >
            <span className="relative z-10">Shop Display Units</span>
            <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-blue-400/0 via-white/20 to-blue-400/0 -translate-x-full group-hover:translate-x-full transition-transform duration-700"></div>
          </Button>
          
          <Button
            variant="primary"
            size="lg"
            href="/upgrade"
            className="group bg-gradient-to-r from-green-600 to-green-500 hover:from-green-500 hover:to-green-400 transform hover:-translate-y-1"
          >
            <span className="relative z-10">Upgrade Your Display</span>
            <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-green-400/0 via-white/20 to-green-400/0 -translate-x-full group-hover:translate-x-full transition-transform duration-700"></div>
          </Button>
          
          <Button
            variant="primary"
            size="lg"
            href="/partners"
            className="group bg-gradient-to-r from-purple-600 to-purple-500 hover:from-purple-500 hover:to-purple-400 transform hover:-translate-y-1"
          >
            <span className="relative z-10">Business Partners</span>
            <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-purple-400/0 via-white/20 to-purple-400/0 -translate-x-full group-hover:translate-x-full transition-transform duration-700"></div>
          </Button>
        </div>
      </div>
      
      <style jsx>{`
        @keyframes float {
          0% {
            transform: translateY(0) translateX(0);
          }
          25% {
            transform: translateY(-10px) translateX(10px);
          }
          50% {
            transform: translateY(0) translateX(20px);
          }
          75% {
            transform: translateY(10px) translateX(10px);
          }
          100% {
            transform: translateY(0) translateX(0);
          }
        }
      `}</style>
    </Section>
  );
};

export default CTA; 