import React, { useState, useEffect } from 'react';

// Import Components
import Layout from '../components/general/Layout';
import Hero from '../components/landing/Hero';
import Features from '../components/landing/Features';
import Dashboard from '../components/landing/Dashboard';
import HowItWorks from '../components/landing/HowItWorks';
import DiagnosticTools from '../components/landing/DiagnosticTools';
import NavigationSection from '../components/landing/NavigationSection';
import Testimonials from '../components/landing/Testimonials';
import CTA from '../components/landing/CTA';

const MainLandingPage = () => {
  const [scrollY, setScrollY] = useState(0);
  
  // For scrolling effect
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Layout scrollY={scrollY}>
      <Hero />
      <Features />
      <Dashboard />
      <HowItWorks />
      <DiagnosticTools />
      <NavigationSection />
      <Testimonials />
      <CTA />
    </Layout>
  );
};

export default MainLandingPage;