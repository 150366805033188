import React from 'react';
import { CheckCircle, Wrench, Car, Settings, Code, Clock, MapPin, Gauge } from 'lucide-react';

const HowItWorks = () => {
  return (
    <section id="how-it-works" className="py-16 md:py-24 bg-gray-900 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900 to-blue-900/10 opacity-70"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <div className="inline-block bg-blue-600/20 border border-blue-500/30 rounded-lg px-3 py-1 text-sm text-blue-400 font-medium mb-4">
            SIMPLE PROCESS
          </div>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">How It Works</h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Getting our advanced display system in your vehicle is easy and straightforward.
          </p>
        </div>
        
        <div className="max-w-5xl mx-auto">
          <div className="relative">
            {/* Connection line */}
            <div className="absolute left-1/2 top-0 bottom-0 w-1 bg-gradient-to-b from-blue-500 via-purple-500 to-green-500 transform -translate-x-1/2 hidden md:block"></div>
            
            <div className="space-y-12 relative">
              {/* Step 1 */}
              <div className="flex flex-col md:flex-row items-center gap-8 relative">
                <div className="md:w-1/2 text-right md:text-left order-1">
                  <h3 className="text-2xl font-bold mb-2 text-blue-400">Choose Your Product</h3>
                  <p className="text-gray-300">
                    Visit our online store or authorized retailers to select the perfect display system for your vehicle model. We offer new units, upgrade kits, and custom B2B solutions.
                  </p>
                  <ul className="mt-4 space-y-2 text-gray-400 text-sm">
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-blue-400 flex-shrink-0 mt-0.5" />
                      <span>Compatible with 95% of vehicle models</span>
                    </li>
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-blue-400 flex-shrink-0 mt-0.5" />
                      <span>Free consultation with our experts</span>
                    </li>
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-blue-400 flex-shrink-0 mt-0.5" />
                      <span>Detailed specifications for each product</span>
                    </li>
                  </ul>
                </div>
                <div className="md:absolute left-1/2 transform -translate-x-1/2 z-10 order-0">
                  <div className="w-16 h-16 rounded-full bg-gradient-to-br from-blue-600 to-blue-400 flex items-center justify-center text-white font-bold text-xl shadow-lg shadow-blue-500/20">
                    1
                  </div>
                </div>
                <div className="md:w-1/2 order-2">
                  <div className="bg-gray-800/90 p-6 rounded-lg shadow-lg border border-gray-700">
                    <div className="flex flex-col space-y-4">
                      <div className="flex items-center space-x-4">
                        <div className="w-12 h-12 rounded-full bg-blue-500/20 flex items-center justify-center">
                          <Car className="w-6 h-6 text-blue-400" />
                        </div>
                        <div>
                          <h4 className="font-medium">New Display Units</h4>
                          <p className="text-sm text-gray-400">Complete systems with all features</p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <div className="w-12 h-12 rounded-full bg-green-500/20 flex items-center justify-center">
                          <Settings className="w-6 h-6 text-green-400" />
                        </div>
                        <div>
                          <h4 className="font-medium">Upgrade Kits</h4>
                          <p className="text-sm text-gray-400">Enhance your existing display</p>
                        </div>
                      </div>
                      <div className="flex items-center space-x-4">
                        <div className="w-12 h-12 rounded-full bg-purple-500/20 flex items-center justify-center">
                          <Code className="w-6 h-6 text-purple-400" />
                        </div>
                        <div>
                          <h4 className="font-medium">B2B Solutions</h4>
                          <p className="text-sm text-gray-400">Custom fleet management systems</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Step 2 */}
              <div className="flex flex-col md:flex-row items-center gap-8 relative">
                <div className="md:w-1/2 text-right md:text-left order-1">
                  <h3 className="text-2xl font-bold mb-2 text-purple-400">Customize Your System</h3>
                  <p className="text-gray-300">
                    Use our online configuration tool to personalize your display system. Choose the features, metrics, and visual settings that match your driving style and preferences.
                  </p>
                  <ul className="mt-4 space-y-2 text-gray-400 text-sm">
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-purple-400 flex-shrink-0 mt-0.5" />
                      <span>Multiple display themes and layouts</span>
                    </li>
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-purple-400 flex-shrink-0 mt-0.5" />
                      <span>Select which metrics appear in priority</span>
                    </li>
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-purple-400 flex-shrink-0 mt-0.5" />
                      <span>Customize alert thresholds and notifications</span>
                    </li>
                  </ul>
                </div>
                <div className="md:absolute left-1/2 transform -translate-x-1/2 z-10 order-0">
                  <div className="w-16 h-16 rounded-full bg-gradient-to-br from-purple-600 to-purple-400 flex items-center justify-center text-white font-bold text-xl shadow-lg shadow-purple-500/20">
                    2
                  </div>
                </div>
                <div className="md:w-1/2 order-2">
                  <div className="bg-gray-800/90 p-6 rounded-lg shadow-lg border border-gray-700">
                    <div className="mb-4">
                      <div className="h-10 bg-gray-700 rounded-lg mb-3 flex items-center px-3">
                        <div className="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
                        <div className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></div>
                        <div className="w-3 h-3 rounded-full bg-green-500 mr-2"></div>
                        <div className="flex-1 text-center text-sm text-gray-400">Customization Portal</div>
                      </div>
                      <div className="bg-gray-900 rounded-lg p-4 space-y-3">
                        <div className="flex justify-between items-center text-sm">
                          <span className="text-gray-300">Theme Selection</span>
                          <span className="text-blue-400">Dark Blue</span>
                        </div>
                        <div className="flex justify-between items-center text-sm">
                          <span className="text-gray-300">Display Layout</span>
                          <span className="text-blue-400">Sport Mode</span>
                        </div>
                        <div className="flex justify-between items-center text-sm">
                          <span className="text-gray-300">Metrics Displayed</span>
                          <span className="text-blue-400">Performance Pack</span>
                        </div>
                        <div className="flex justify-between items-center text-sm">
                          <span className="text-gray-300">Alert Preferences</span>
                          <span className="text-blue-400">Standard</span>
                        </div>
                        <div className="mt-4">
                          <div className="bg-blue-600 text-white text-center py-2 rounded-lg text-sm">Save Configuration</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Step 3 */}
              <div className="flex flex-col md:flex-row items-center gap-8 relative">
                <div className="md:w-1/2 text-right md:text-left order-1">
                  <h3 className="text-2xl font-bold mb-2 text-green-400">Professional Installation</h3>
                  <p className="text-gray-300">
                    Schedule an appointment with our certified technicians for professional installation, or follow our comprehensive DIY guides for simpler upgrades.
                  </p>
                  <ul className="mt-4 space-y-2 text-gray-400 text-sm">
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-green-400 flex-shrink-0 mt-0.5" />
                      <span>Nationwide network of certified installers</span>
                    </li>
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-green-400 flex-shrink-0 mt-0.5" />
                      <span>Step-by-step installation videos</span>
                    </li>
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-green-400 flex-shrink-0 mt-0.5" />
                      <span>Remote assistance available if needed</span>
                    </li>
                  </ul>
                </div>
                <div className="md:absolute left-1/2 transform -translate-x-1/2 z-10 order-0">
                  <div className="w-16 h-16 rounded-full bg-gradient-to-br from-green-600 to-green-400 flex items-center justify-center text-white font-bold text-xl shadow-lg shadow-green-500/20">
                    3
                  </div>
                </div>
                <div className="md:w-1/2 order-2">
                  <div className="bg-gray-800/90 p-6 rounded-lg shadow-lg border border-gray-700">
                    <div className="flex flex-col space-y-4">
                      <div>
                        <div className="flex items-center mb-2">
                          <Wrench className="w-5 h-5 text-green-400 mr-2" />
                          <h4 className="font-medium">Installation Process</h4>
                        </div>
                        <div className="space-y-3">
                          <div className="flex items-center">
                            <div className="w-8 h-8 rounded-full bg-gray-700 text-white flex items-center justify-center text-sm mr-3">1</div>
                            <div className="text-sm text-gray-300">Remove old display unit</div>
                          </div>
                          <div className="flex items-center">
                            <div className="w-8 h-8 rounded-full bg-gray-700 text-white flex items-center justify-center text-sm mr-3">2</div>
                            <div className="text-sm text-gray-300">Connect wiring harness</div>
                          </div>
                          <div className="flex items-center">
                            <div className="w-8 h-8 rounded-full bg-gray-700 text-white flex items-center justify-center text-sm mr-3">3</div>
                            <div className="text-sm text-gray-300">Install new display unit</div>
                          </div>
                          <div className="flex items-center">
                            <div className="w-8 h-8 rounded-full bg-gray-700 text-white flex items-center justify-center text-sm mr-3">4</div>
                            <div className="text-sm text-gray-300">Run diagnostics check</div>
                          </div>
                          <div className="flex items-center">
                            <div className="w-8 h-8 rounded-full bg-gray-700 text-white flex items-center justify-center text-sm mr-3">5</div>
                            <div className="text-sm text-gray-300">Calibrate and test system</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Step 4 */}
              <div className="flex flex-col md:flex-row items-center gap-8 relative">
                <div className="md:w-1/2 text-right md:text-left order-1">
                  <h3 className="text-2xl font-bold mb-2 text-cyan-400">Enjoy Your Enhanced Driving Experience</h3>
                  <p className="text-gray-300">
                    Experience the future of driving with your new display system. Access real-time metrics, vehicle diagnostics, navigation, and more through an intuitive interface.
                  </p>
                  <ul className="mt-4 space-y-2 text-gray-400 text-sm">
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-cyan-400 flex-shrink-0 mt-0.5" />
                      <span>Automatic over-the-air updates</span>
                    </li>
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-cyan-400 flex-shrink-0 mt-0.5" />
                      <span>24/7 technical support</span>
                    </li>
                    <li className="flex items-start md:justify-start gap-2">
                      <CheckCircle className="w-4 h-4 text-cyan-400 flex-shrink-0 mt-0.5" />
                      <span>Regular new features and improvements</span>
                    </li>
                  </ul>
                </div>
                <div className="md:absolute left-1/2 transform -translate-x-1/2 z-10 order-0">
                  <div className="w-16 h-16 rounded-full bg-gradient-to-br from-cyan-600 to-cyan-400 flex items-center justify-center text-white font-bold text-xl shadow-lg shadow-cyan-500/20">
                    4
                  </div>
                </div>
                <div className="md:w-1/2 order-2">
                  <div className="bg-gray-800/90 p-6 rounded-lg shadow-lg border border-gray-700">
                    <div className="mb-4 text-center">
                      <Car className="w-16 h-16 text-blue-400 mx-auto mb-3" />
                      <div className="text-lg font-medium mb-1">Your Connected Vehicle</div>
                      <p className="text-sm text-gray-400 mb-4">All systems activated and ready</p>
                      
                      <div className="grid grid-cols-2 gap-3">
                        <div className="bg-gray-900 p-3 rounded-lg">
                          <Gauge className="w-6 h-6 text-blue-400 mx-auto mb-1" />
                          <div className="text-sm">Real-time Metrics</div>
                        </div>
                        <div className="bg-gray-900 p-3 rounded-lg">
                          <Wrench className="w-6 h-6 text-green-400 mx-auto mb-1" />
                          <div className="text-sm">Diagnostics</div>
                        </div>
                        <div className="bg-gray-900 p-3 rounded-lg">
                          <MapPin className="w-6 h-6 text-purple-400 mx-auto mb-1" />
                          <div className="text-sm">Navigation</div>
                        </div>
                        <div className="bg-gray-900 p-3 rounded-lg">
                          <Clock className="w-6 h-6 text-red-400 mx-auto mb-1" />
                          <div className="text-sm">Performance</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks; 