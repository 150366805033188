import React from 'react';
import PageContainer from './PageContainer';

const Section = ({ 
  children, 
  className = "", 
  id = "",
  fullWidth = false,
  containerClassName = ""
}) => {
  return (
    <section id={id} className={`py-16 md:py-24 relative ${className}`}>
      <PageContainer fullWidth={fullWidth} className={containerClassName}>
        {children}
      </PageContainer>
    </section>
  );
};

export default Section; 