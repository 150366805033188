import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Car, Search, Menu } from 'lucide-react';

const Header = ({ scrollY }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${scrollY > 50 ? 'bg-gray-900/95 backdrop-blur-sm shadow-lg' : 'bg-transparent'}`}>
      <div className="container mx-auto px-4 py-4 flex justify-between items-center">
        <Link to="/" className="flex items-center space-x-2">
          <Car className="h-8 w-8 text-blue-500" />
          <span className="text-xl font-bold">CarNebula</span>
        </Link>
        
        {/* Desktop Navigation - Only Home */}
        <nav className="hidden md:flex space-x-8">
          <Link to="/" className="hover:text-blue-400 transition">Home</Link>
        </nav>
        
        {/* User Actions - Simplified */}
        <div className="flex items-center space-x-4">
          <button className="relative p-2 text-gray-300 hover:text-blue-400 transition">
            <Search className="h-5 w-5" />
          </button>
          
          {/* Mobile Menu Button */}
          <button 
            className="md:hidden text-white hover:text-blue-400 transition"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <Menu className="h-6 w-6" />
          </button>
        </div>
      </div>
      
      {/* Mobile Menu - Only Home */}
      {mobileMenuOpen && (
        <div className="md:hidden bg-gray-900/95 border-t border-gray-800">
          <div className="container mx-auto px-4 py-3">
            <nav className="flex flex-col space-y-3">
              <Link to="/" className="py-2 hover:text-blue-400 transition">Home</Link>
            </nav>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;