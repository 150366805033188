import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserProfile } from '../services/api';
import { loadCartFromStorage, saveCartToStorage } from '../utils/cartUtils';
import { supabase } from '../utils/supabase';
import { isAuthenticated as checkIsAuthenticated, getCurrentUser, loginWithEmailPassword, logout as authLogout, register as registerUser } from '../utils/auth';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const [user, setUser] = useState(null);
  const [authError, setAuthError] = useState(null);
  const [appLoading, setAppLoading] = useState(true); // Initial app loading
  const [authLoading, setAuthLoading] = useState(false); // Authentication actions loading
  const [initialized, setInitialized] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  // Initialize app and check auth status
  useEffect(() => {
    const initApp = async () => {
      try {
        setAppLoading(true);
        
        // Load cart from storage
        const savedCart = loadCartFromStorage();
        if (savedCart) setCart(savedCart);
        
        // Check if user is authenticated
        const { data: { session } } = await supabase.auth.getSession();
        
        if (session) {
          setIsAuthenticated(true);
          const { data: { user } } = await supabase.auth.getUser();
          if (user) {
            try {
              // Try to get the user profile
              const userProfile = await getUserProfile(user.id);
              setUser(userProfile);
            } catch (err) {
              console.error("Error loading user profile:", err);
              // We have authentication but no profile - could create one here
            }
          }
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
      } catch (err) {
        console.error("Error initializing app:", err);
      } finally {
        setAppLoading(false);
        setInitialized(true);
      }
    };
    
    initApp();
    
    // Subscribe to auth changes
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === 'SIGNED_IN' && session) {
          setIsAuthenticated(true);
          try {
            const userProfile = await getUserProfile(session.user.id);
            setUser(userProfile);
          } catch (err) {
            console.error("Error loading profile after sign in:", err);
          }
        } else if (event === 'SIGNED_OUT') {
          setIsAuthenticated(false);
          setUser(null);
        }
      }
    );
    
    return () => {
      if (authListener && authListener.subscription) {
        authListener.subscription.unsubscribe();
      }
    };
  }, []);
  
  // Save cart whenever it changes
  useEffect(() => {
    if (initialized) {
      saveCartToStorage(cart);
    }
  }, [cart, initialized]);
  
  // Login function
  const login = async (email, password) => {
    try {
      setAuthLoading(true);
      setAuthError(null);
      
      const { success, data, error } = await loginWithEmailPassword(email, password);
      
      if (!success) {
        setAuthError(error || 'Failed to login');
        return false;
      }
      
      // Authentication handled by the auth listener
      return true;
    } catch (err) {
      console.error('Login error:', err);
      setAuthError(err.message || 'An unexpected error occurred');
      return false;
    } finally {
      setAuthLoading(false);
    }
  };
  
  // Logout function
  const logout = async () => {
    try {
      setAuthLoading(true);
      
      const { success, error } = await authLogout();
      
      if (!success) {
        console.error('Logout error:', error);
        return false;
      }
      
      // Authentication state handled by the auth listener
      return true;
    } catch (err) {
      console.error('Logout error:', err);
      return false;
    } finally {
      setAuthLoading(false);
    }
  };

  // Register function
  async function handleRegister(email, password, userData) {
    setLoading(true);
    setAuthError(null);
    try {
      const { success, error } = await registerUser(email, password, userData);
      if (!success) throw new Error(error);
      return true;
    } catch (err) {
      setAuthError(err.message);
      return false;
    } finally {
      setLoading(false);
    }
  }
  
  // Add to cart function
  const addToCart = (product) => {
    setCart(prevCart => {
      // Check if product is already in cart
      const existingItemIndex = prevCart.findIndex(item => item.id === product.id);
      
      if (existingItemIndex >= 0) {
        // Product exists, update quantity
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex] = {
          ...updatedCart[existingItemIndex],
          quantity: (updatedCart[existingItemIndex].quantity || 1) + (product.quantity || 1)
        };
        return updatedCart;
      } else {
        // Add new product to cart
        return [...prevCart, { ...product, quantity: product.quantity || 1 }];
      }
    });
  };
  
  // Remove from cart function
  const removeFromCart = (productId) => {
    setCart(prevCart => prevCart.filter(item => item.id !== productId));
  };
  
  // Update cart item quantity
  const updateCartItemQuantity = (productId, quantity) => {
    if (quantity <= 0) {
      removeFromCart(productId);
      return;
    }
    
    setCart(prevCart => {
      return prevCart.map(item => {
        if (item.id === productId) {
          return { ...item, quantity };
        }
        return item;
      });
    });
  };
  
  // Increment cart item quantity
  const incrementCartItem = (productId) => {
    setCart(prevCart => {
      return prevCart.map(item => {
        if (item.id === productId) {
          return { ...item, quantity: (item.quantity || 1) + 1 };
        }
        return item;
      });
    });
  };
  
  // Decrement cart item quantity
  const decrementCartItem = (productId) => {
    setCart(prevCart => {
      const item = prevCart.find(item => item.id === productId);
      if (item && item.quantity <= 1) {
        return prevCart.filter(item => item.id !== productId);
      }
      
      return prevCart.map(item => {
        if (item.id === productId) {
          return { ...item, quantity: Math.max(1, item.quantity - 1) };
        }
        return item;
      });
    });
  };
  
  // Clear cart
  const clearCart = () => {
    setCart([]);
  };
  
  // Calculate cart total
  const cartTotal = cart.reduce((total, item) => {
    return total + (item.price * (item.quantity || 1));
  }, 0);
  
  // Calculate cart item count
  const cartItemCount = cart.reduce((count, item) => count + (item.quantity || 1), 0);
  
  // Context value
  const contextValue = {
    cart,
    addToCart,
    removeFromCart,
    updateCartItemQuantity,
    incrementCartItem,    // Add the new function
    decrementCartItem,    // Add the new function
    clearCart,
    cartTotal,
    cartItemCount,        // Add cart item count
    wishlist,
    setWishlist,
    user,
    setUser,
    login,
    logout,
    register: handleRegister,
    appLoading,        // For app initialization loading
    authLoading,       // For authentication actions loading
    loading,
    authError,
    isAuthenticated
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);