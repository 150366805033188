import Cookies from 'js-cookie';
import { supabase } from './supabase';

const DEFAULT_USER_ID = 1;

// Store JWT token in localStorage
export const setAuthToken = () => {
  // Not needed with Supabase
  console.warn('setAuthToken is not needed with Supabase');
};

// Get JWT token from localStorage
export const getAuthToken = async () => {
  const { data } = await supabase.auth.getSession();
  return data?.session?.access_token || null;
};

// Remove JWT token from localStorage
export const removeAuthToken = async () => {
  await supabase.auth.signOut();
};

/**
 * Checks if user is authenticated
 * @returns {Promise<boolean>} True if authenticated, false otherwise
 */
export const isAuthenticated = async () => {
  try {
    const { data, error } = await supabase.auth.getSession();
    if (error) {
      console.error('Error checking auth status:', error);
      return false;
    }
    return !!data.session;
  } catch (err) {
    console.error('Error checking authentication status:', err);
    return false;
  }
};

/**
 * Gets the current authenticated user
 * @returns {Promise<Object|null>} User object or null if not authenticated
 */
export const getCurrentUser = async () => {
  try {
    const { data, error } = await supabase.auth.getUser();
    if (error) {
      console.error('Error getting current user:', error);
      return null;
    }
    return data.user;
  } catch (err) {
    console.error('Error getting current user:', err);
    return null;
  }
};

/**
 * Login with email and password
 * @param {string} email User email
 * @param {string} password User password
 * @returns {Promise<{success: boolean, data?: Object, error?: string}>} Result object
 */
export const loginWithEmailPassword = async (email, password) => {
  try {
    console.log('Starting login process in auth.js');
    
    // For demo purposes, allow a default login
    if (email === 'alex.johnson@example.com' && password === 'password123') {
      console.log('Using demo login credentials');
      
      // Create a demo user object
      const demoUser = {
        id: 'demo-user-id',
        email: 'alex.johnson@example.com',
        user_metadata: {
          name: 'Alex Johnson'
        }
      };
      
      // Use Supabase's real session methods for consistency
      try {
        // Sign out any current session first to ensure clean state
        await supabase.auth.signOut();
        
        // Use Supabase to set session - this creates a proper auth event
        const { data, error } = await supabase.auth.signInWithPassword({
          email: 'alex.johnson@example.com',
          password: 'password123'
        });
        
        if (error) {
          console.log('Demo login via Supabase failed, falling back to manual approach');
          // Return successful login response with demo data
          return { 
            success: true, 
            data: { 
              user: demoUser,
              session: {
                access_token: 'demo-token',
                refresh_token: 'demo-refresh-token',
                user: demoUser
              }
            } 
          };
        }
        
        console.log('Demo login successful via Supabase');
        return { success: true, data };
      } catch (err) {
        console.log('Could not use Supabase for demo login, using alternative approach', err);
        // Return successful login response with demo data
        return { 
          success: true, 
          data: { 
            user: demoUser,
            session: {
              access_token: 'demo-token',
              refresh_token: 'demo-refresh-token',
              user: demoUser
            }
          } 
        };
      }
    }
    
    // Real authentication with Supabase
    console.log('Attempting real authentication with Supabase');
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    if (error) {
      console.error('Login failed:', error);
      return { success: false, error: error.message };
    }
    
    console.log('Login successful:', data?.user?.email);
    return { success: true, data };
    
  } catch (err) {
    console.error('Unexpected login error:', err);
    return { success: false, error: 'An unexpected error occurred during login' };
  }
};

/**
 * Sign up with email and password
 * @param {string} email User email
 * @param {string} password User password
 * @param {Object} userData Additional user data
 * @returns {Promise<{success: boolean, data?: Object, error?: string}>} Result object
 */
export const signupWithEmailPassword = async (email, password, userData = {}) => {
  try {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: { data: userData }
    });

    if (!error) {
      const user = data?.user;
      if (user) {
        const { error: insertError } = await supabase
          .from("Users")
          .insert({
            auth_id: user.id,
            email: user.email,
            name: userData.name
          });
        if (insertError) {
          console.error('Error inserting user into Users table:', insertError);
        }
      }
    }

    if (error) {
      return { success: false, error: error.message };
    }
    
    return { success: true, data };
  } catch (err) {
    console.error('Signup error:', err);
    return { success: false, error: 'An unexpected error occurred during signup' };
  }
};

/**
 * Register a new user
 * @param {string} email User email
 * @param {string} password User password
 * @param {Object} userData Additional user data
 * @returns {Promise<{success: boolean, data?: Object, error?: string}>} Result object
 */
export function register(email, password, userData = {}) {
  return signupWithEmailPassword(email, password, userData);
}

/**
 * Log out the current user
 * @returns {Promise<{success: boolean, error?: string}>} Result object
 */
export const logout = async () => {
  try {
    const { error } = await supabase.auth.signOut();
    
    if (error) {
      return { success: false, error: error.message };
    }
    
    return { success: true };
  } catch (err) {
    console.error('Logout error:', err);
    return { success: false, error: 'An unexpected error occurred during logout' };
  }
};

/**
 * Updates the user's profile in the Users table
 * @param {Object} profileData Profile data to update
 * @returns {Promise<{success: boolean, data?: Object, error?: string}>} Result object
 */
export const updateProfile = async (profileData) => {
  try {
    const user = await getCurrentUser();
    if (!user) {
      return { success: false, error: 'User not authenticated' };
    }
    
    const { data, error } = await supabase
      .from('Users')
      .update(profileData)
      .eq('auth_id', user.id)
      .select()
      .single();
      
    if (error) {
      return { success: false, error: error.message };
    }
    
    return { success: true, data };
  } catch (err) {
    console.error('Profile update error:', err);
    return { success: false, error: 'An unexpected error occurred updating profile' };
  }
};

/**
 * Get current user ID from token
 * @returns {Promise<string|null>} User ID or null if not authenticated
 */
export const getCurrentUserId = async () => {
  const { data } = await supabase.auth.getSession();
  return data?.session?.user?.id || null;
};

// Get headers with authorization token
export const getAuthHeaders = async () => {
  const token = await getAuthToken();
  return {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };
};

// Legacy methods using cookies
export const loginLegacy = () => {
  Cookies.set('userId', DEFAULT_USER_ID, { expires: 7 }); // 7 days
  return DEFAULT_USER_ID;
};

export const logoutLegacy = () => {
  Cookies.remove('userId');
};

export const isLoggedInLegacy = () => {
  return !!Cookies.get('userId');
};