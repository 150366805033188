import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, scrollY }) => {
  return (
    <div className="min-h-screen bg-gray-900 text-white font-sans">
      <Header scrollY={scrollY} />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout; 