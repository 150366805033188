import React from 'react';
import { ChevronDown, CheckCircle, ShoppingCart, User } from 'lucide-react';

const Hero = () => {
  return (
    <section id="hero" className="pt-24 pb-16 md:pt-32 md:pb-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-black z-0">
        <div className="absolute inset-0 bg-gradient-to-br from-blue-900/40 to-purple-900/40 z-0"></div>
        {/* Animated circuit board pattern */}
        <div className="absolute inset-0 opacity-10">
          <div className="absolute h-full w-full">
            {[...Array(20)].map((_, i) => (
              <div 
                key={i} 
                className="absolute bg-blue-500" 
                style={{
                  height: '1px',
                  width: `${Math.random() * 20 + 5}%`,
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 80}%`,
                  opacity: Math.random() * 0.5 + 0.3,
                  animation: `pulse ${Math.random() * 4 + 3}s infinite alternate`
                }}
              />
            ))}
            {[...Array(20)].map((_, i) => (
              <div 
                key={i} 
                className="absolute bg-blue-500" 
                style={{
                  width: '1px',
                  height: `${Math.random() * 20 + 5}%`,
                  left: `${Math.random() * 100}%`,
                  top: `${Math.random() * 80}%`,
                  opacity: Math.random() * 0.5 + 0.3,
                  animation: `pulse ${Math.random() * 4 + 3}s infinite alternate`
                }}
              />
            ))}
            {[...Array(15)].map((_, i) => (
              <div 
                key={i} 
                className="absolute rounded-full bg-blue-400" 
                style={{
                  height: '4px',
                  width: '4px',
                  top: `${Math.random() * 100}%`,
                  left: `${Math.random() * 100}%`,
                  opacity: Math.random() * 0.5 + 0.5,
                  animation: `blink ${Math.random() * 2 + 1}s infinite alternate`
                }}
              />
            ))}
          </div>
        </div>
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-col md:flex-row items-center justify-between gap-12">
          <div className="md:w-1/2">
            <div className="relative mb-3">
              <div className="inline-block bg-blue-600/20 border border-blue-500/30 rounded-lg px-3 py-1 text-sm text-blue-400 font-medium mb-1">
                NEXT GENERATION DISPLAY TECHNOLOGY
              </div>
            </div>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight mb-6">
              Advanced <span className="text-blue-400 relative">
                Car Display Systems
                <span className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-blue-500 to-purple-500"></span>
              </span> For Every Vehicle
            </h1>
            <p className="text-xl text-gray-300 mb-8">
              Premium dashboard displays with cutting-edge monitoring, diagnostics, and analytics capabilities. Available as new units or upgrades to existing displays.
            </p>
            <div className="flex flex-wrap gap-4 mb-8">
              <div className="flex items-center space-x-4">
                <a href="/cart" className="text-gray-300 hover:text-blue-400 transition">
                  <ShoppingCart className="h-5 w-5" />
                </a>
                <a href="/account" className="text-gray-300 hover:text-blue-400 transition">
                  <User className="h-5 w-5" />
                </a>
                <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition shadow-lg">
                  Shop Now
                </button>
              </div>
              <button className="group border border-blue-400 text-blue-400 hover:bg-blue-400/10 px-6 py-3 rounded-md text-lg font-medium transition relative overflow-hidden">
                <span className="relative z-10">Upgrade Options</span>
                <div className="absolute inset-0 w-0 bg-blue-400 transition-all duration-300 group-hover:w-full opacity-10"></div>
              </button>
            </div>
            
            <div className="flex items-center space-x-4 text-sm">
              <div className="flex items-center">
                <CheckCircle className="text-green-500 w-5 h-5 mr-1" />
                <span>Premium Quality</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="text-green-500 w-5 h-5 mr-1" />
                <span>2-Year Warranty</span>
              </div>
              <div className="flex items-center">
                <CheckCircle className="text-green-500 w-5 h-5 mr-1" />
                <span>Free Shipping</span>
              </div>
            </div>
          </div>
          
          <div className="md:w-1/2 relative">
            <div className="absolute -inset-4 bg-gradient-to-tr from-blue-600/20 via-purple-600/10 to-cyan-600/20 blur-lg rounded-2xl opacity-70 group-hover:opacity-100 transition duration-1000 animate-pulse"></div>
            <div className="bg-gradient-to-tr from-blue-600/20 to-purple-600/20 rounded-xl p-4 shadow-2xl relative">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500 to-purple-500 rounded-xl opacity-20 blur"></div>
              <div className="absolute -right-5 -top-5 w-20 h-20 bg-blue-500/20 rounded-full blur-xl"></div>
              <div className="absolute -left-7 -bottom-7 w-28 h-28 bg-purple-500/20 rounded-full blur-xl"></div>
              
              <div className="relative rounded-lg overflow-hidden shadow-2xl">
                <img 
                  src={`${process.env.PUBLIC_URL}/assets/dashboard.jpg`} 
                  alt="Dashboard Display" 
                  className="w-full h-auto object-cover"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 animate-bounce">
          <ChevronDown className="w-8 h-8 text-blue-400" />
        </div>
      </div>
      
      {/* Animated gradient orbs in background */}
      <div className="absolute bottom-0 left-1/4 w-64 h-64 bg-blue-500/20 rounded-full filter blur-3xl opacity-30 animate-blob"></div>
      <div className="absolute top-1/4 right-1/3 w-72 h-72 bg-purple-500/20 rounded-full filter blur-3xl opacity-30 animate-blob animation-delay-2000"></div>
      <div className="absolute bottom-1/3 right-1/4 w-60 h-60 bg-cyan-500/20 rounded-full filter blur-3xl opacity-30 animate-blob animation-delay-4000"></div>
      
      <style jsx>{`
        @keyframes blob {
          0% { transform: translate(0px, 0px) scale(1); }
          33% { transform: translate(30px, -50px) scale(1.1); }
          66% { transform: translate(-20px, 20px) scale(0.9); }
          100% { transform: translate(0px, 0px) scale(1); }
        }
        @keyframes pulse {
          0% { opacity: 0.2; }
          100% { opacity: 0.8; }
        }
        @keyframes blink {
          0% { opacity: 0.3; }
          100% { opacity: 1; }
        }
        .animation-delay-2000 {
          animation-delay: 2s;
        }
        .animation-delay-4000 {
          animation-delay: 4s;
        }
        .animate-blob {
          animation: blob 7s infinite;
        }
      `}</style>
    </section>
  );
};

export default Hero;
