import React from 'react';
import { Map, BarChart3, Clock } from 'lucide-react';

const NavigationSection = () => {
  return (
    <section className="py-16 md:py-24 bg-gray-900 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900 to-blue-900/10 opacity-70"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <div className="inline-block bg-blue-600/20 border border-blue-500/30 rounded-lg px-3 py-1 text-sm text-blue-400 font-medium mb-4">
            SMART NAVIGATION
          </div>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Navigate with Confidence</h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Advanced navigation features with trip analytics and driving pattern insights.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="order-2 md:order-1">
            <div className="relative rounded-xl overflow-hidden shadow-2xl">
              <img 
                src={`${process.env.PUBLIC_URL}/assets/navigation.jpg`} 
                alt="Smart Navigation System"
                className="w-full h-auto rounded-lg"
              />
            </div>
          </div>
          
          <div className="order-1 md:order-2">
            <h3 className="text-2xl font-bold mb-4 text-purple-400">Smart Journey Tracking</h3>
            <ul className="space-y-4">
              <li className="flex items-start gap-3">
                <div className="bg-purple-500/20 p-2 rounded-full mt-1">
                  <Map className="text-purple-400 w-5 h-5" />
                </div>
                <div>
                  <div className="font-medium text-lg">Real-time Route Optimization</div>
                  <p className="text-gray-400">Automatically finds the fastest routes based on current traffic conditions.</p>
                </div>
              </li>
              <li className="flex items-start gap-3">
                <div className="bg-purple-500/20 p-2 rounded-full mt-1">
                  <BarChart3 className="text-purple-400 w-5 h-5" />
                </div>
                <div>
                  <div className="font-medium text-lg">Trip Analytics</div>
                  <p className="text-gray-400">Analyze your driving patterns, frequent destinations, and efficiency metrics.</p>
                </div>
              </li>
              <li className="flex items-start gap-3">
                <div className="bg-purple-500/20 p-2 rounded-full mt-1">
                  <Clock className="text-purple-400 w-5 h-5" />
                </div>
                <div>
                  <div className="font-medium text-lg">Journey History</div>
                  <p className="text-gray-400">Review past trips with detailed information on routes, stops, and driving behavior.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        
        <div className="mt-16">
          <div className="relative rounded-xl overflow-hidden">
            <div className="absolute -inset-1 bg-gradient-to-r from-purple-600/30 via-blue-600/30 to-purple-600/30 blur-lg opacity-50"></div>
            <div className="relative rounded-xl overflow-hidden shadow-2xl">
              <img 
                src={`${process.env.PUBLIC_URL}/assets/rides_history.jpg`} 
                alt="Rides History Tracking"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NavigationSection; 