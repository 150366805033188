import React from 'react';

const Button = ({ 
  children, 
  className = "", 
  variant = "primary", // primary, secondary, outline, text
  size = "md", // sm, md, lg
  href,
  ...props 
}) => {
  // Define variant classes
  const variantClasses = {
    primary: "bg-blue-600 hover:bg-blue-700 text-white shadow-lg shadow-blue-500/20 hover:shadow-blue-500/40",
    secondary: "bg-gray-700 hover:bg-gray-600 text-white",
    outline: "border border-blue-500 text-blue-400 hover:bg-blue-500/10",
    text: "text-blue-400 hover:text-blue-300"
  };
  
  // Define size classes
  const sizeClasses = {
    sm: "px-3 py-1.5 text-sm",
    md: "px-4 py-2",
    lg: "px-6 py-3 text-lg"
  };
  
  // Base classes for all buttons
  const baseClasses = "rounded-md transition duration-300 inline-flex items-center justify-center font-medium";
  
  // Combine all classes
  const buttonClasses = `${baseClasses} ${variantClasses[variant]} ${sizeClasses[size]} ${className}`;
  
  // Return anchor tag if href is provided, otherwise button
  if (href) {
    return (
      <a href={href} className={buttonClasses} {...props}>
        {children}
      </a>
    );
  }
  
  return (
    <button className={buttonClasses} {...props}>
      {children}
    </button>
  );
};

export default Button; 