import React, { useState } from 'react';
import { Gauge, Map, Battery, ZapOff, Wrench, Award, Zap, Cpu, Shield, GitBranch } from 'lucide-react';

const Features = () => {
  const [activeFeature, setActiveFeature] = useState(0);
  const [hoveredCard, setHoveredCard] = useState(null);

  const features = [
    {
      icon: <Gauge className="w-12 h-12 text-blue-500" />,
      title: "Real-Time Performance Metrics",
      description: "Monitor speed, RPM, distance, and trip information in real-time with intuitive dashboards and gauges.",
      image: `${process.env.PUBLIC_URL}/assets/realtime_analysis.jpg`
    },
    {
      icon: <Map className="w-12 h-12 text-blue-500" />,
      title: "Navigation & Trip Analytics",
      description: "Track your routes, analyze driving patterns, and view detailed ride histories with advanced analytics.",
      image: `${process.env.PUBLIC_URL}/assets/navigation.jpg`
    },
    {
      icon: <Battery className="w-12 h-12 text-blue-500" />,
      title: "Battery & Vehicle Health",
      description: "Get detailed insights on battery health, engine temperature, and overall vehicle status.",
      image: `${process.env.PUBLIC_URL}/assets/battery_monitor.jpg`
    },
    {
      icon: <ZapOff className="w-12 h-12 text-blue-500" />,
      title: "Diagnostic Tools",
      description: "Run comprehensive diagnostics, identify issues early, and get real-time vehicle health updates.",
      image: `${process.env.PUBLIC_URL}/assets/diagnosis.jpg`
    },
    {
      icon: <Wrench className="w-12 h-12 text-blue-500" />,
      title: "AI Mechanic Assistant",
      description: "Get expert advice for common car problems and maintenance issues through our AI assistant.",
      image: `${process.env.PUBLIC_URL}/assets/AI_mechanic.jpg`
    },
    {
      icon: <Award className="w-12 h-12 text-blue-500" />,
      title: "Achievements & Milestones",
      description: "Track your driving milestones, set goals, and earn achievements as you drive.",
      image: `${process.env.PUBLIC_URL}/assets/achievements.jpg`
    }
  ];

  return (
    <section id="features" className="py-16 md:py-24 bg-gray-900 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900 to-blue-900/20 opacity-50"></div>
      
      {/* Decorative elements */}
      <div className="absolute left-0 top-1/4 w-1/3 h-1/3 bg-blue-500/5 rotate-45 blur-3xl"></div>
      <div className="absolute right-0 bottom-1/4 w-1/3 h-1/3 bg-purple-500/5 -rotate-45 blur-3xl"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <div className="inline-block bg-blue-600/20 border border-blue-500/30 rounded-lg px-3 py-1 text-sm text-blue-400 font-medium mb-4">
            FEATURE-RICH DISPLAY
          </div>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Powerful Features</h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Our comprehensive car display system delivers everything you need to monitor your vehicle's performance and health.
          </p>
        </div>
        
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-16 items-center">
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-blue-600/20 to-purple-600/20 rounded-xl blur"></div>
            <div className="relative bg-gray-800/80 rounded-xl overflow-hidden border border-gray-700 aspect-[4/3]">
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-full max-w-md">
                  <div className="relative h-80 rounded-lg m-2 overflow-hidden">
                    {features.map((feature, index) => (
                      <div
                        key={index}
                        className={`absolute inset-0 bg-gray-900/90 rounded-lg transition-opacity duration-500 ${
                          activeFeature === index ? 'opacity-100 z-10' : 'opacity-0 z-0'
                        }`}
                      >
                        <div className="h-full flex flex-col">
                          <div className="flex-1 relative overflow-hidden">
                            <img 
                              src={feature.image} 
                              alt={feature.title}
                              className="w-full h-full object-contain rounded-lg"
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-30"></div>
                          </div>
                          <div className="p-4 text-center bg-gray-900/80">
                            <h3 className="text-xl font-bold text-white">{feature.title}</h3>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  
                  {/* Feature navigation dots */}
                  <div className="flex justify-center mt-4 gap-2">
                    {features.map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setActiveFeature(index)}
                        className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                          activeFeature === index ? 'bg-blue-500' : 'bg-gray-600 hover:bg-gray-500'
                        }`}
                        aria-label={`View feature ${index + 1}`}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div>
            <div className="space-y-6">
              {features.map((feature, index) => (
                <div 
                  key={index}
                  className={`bg-gray-800/50 rounded-xl p-6 transition-all duration-300 hover:bg-gray-800 border cursor-pointer ${
                    activeFeature === index 
                      ? 'border-blue-500/50 shadow-lg shadow-blue-500/10 bg-gray-800' 
                      : 'border-gray-700 hover:border-blue-500/30'
                  }`}
                  onClick={() => setActiveFeature(index)}
                  onMouseEnter={() => setHoveredCard(index)}
                  onMouseLeave={() => setHoveredCard(null)}
                >
                  <div className="flex items-start gap-4">
                    <div className={`p-3 rounded-lg transition-colors duration-300 ${
                      activeFeature === index 
                        ? 'bg-blue-500/20 text-blue-400' 
                        : 'bg-gray-700/50 text-gray-400 group-hover:bg-blue-500/10 group-hover:text-blue-400'
                    }`}>
                      {feature.icon}
                    </div>
                    <div className="flex-1">
                      <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                      <p className="text-gray-400">{feature.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/* Additional features in smaller cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-gray-800/30 p-5 rounded-xl border border-gray-700 hover:border-blue-500/30 transition-all duration-300 hover:transform hover:scale-105 group">
            <div className="bg-blue-500/10 p-3 rounded-lg w-12 h-12 flex items-center justify-center mb-4 group-hover:bg-blue-500/20 transition-colors duration-300">
              <Zap className="text-blue-400" />
            </div>
            <h3 className="text-lg font-bold mb-2">Fast Refresh Rate</h3>
            <p className="text-gray-400 text-sm">Real-time updates with minimal lag for critical driving data</p>
          </div>
          
          <div className="bg-gray-800/30 p-5 rounded-xl border border-gray-700 hover:border-blue-500/30 transition-all duration-300 hover:transform hover:scale-105 group">
            <div className="bg-purple-500/10 p-3 rounded-lg w-12 h-12 flex items-center justify-center mb-4 group-hover:bg-purple-500/20 transition-colors duration-300">
              <Cpu className="text-purple-400" />
            </div>
            <h3 className="text-lg font-bold mb-2">Powerful Hardware</h3>
            <p className="text-gray-400 text-sm">High-performance components designed for durability in any climate</p>
          </div>
          
          <div className="bg-gray-800/30 p-5 rounded-xl border border-gray-700 hover:border-blue-500/30 transition-all duration-300 hover:transform hover:scale-105 group">
            <div className="bg-green-500/10 p-3 rounded-lg w-12 h-12 flex items-center justify-center mb-4 group-hover:bg-green-500/20 transition-colors duration-300">
              <Shield className="text-green-400" />
            </div>
            <h3 className="text-lg font-bold mb-2">Data Security</h3>
            <p className="text-gray-400 text-sm">End-to-end encryption for all your sensitive vehicle information</p>
          </div>
          
          <div className="bg-gray-800/30 p-5 rounded-xl border border-gray-700 hover:border-blue-500/30 transition-all duration-300 hover:transform hover:scale-105 group">
            <div className="bg-red-500/10 p-3 rounded-lg w-12 h-12 flex items-center justify-center mb-4 group-hover:bg-red-500/20 transition-colors duration-300">
              <GitBranch className="text-red-400" />
            </div>
            <h3 className="text-lg font-bold mb-2">Regular Updates</h3>
            <p className="text-gray-400 text-sm">Continuous improvement with free feature and security updates</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features; 