import React, { useState } from 'react';
import { Gauge, Battery, Award, Navigation, Wrench, Car } from 'lucide-react';

const Dashboard = () => {
  const [selectedVehicle, setSelectedVehicle] = useState('sedan');

  return (
    <section id="dashboard" className="py-16 md:py-24 bg-gradient-to-b from-gray-900 to-gray-800 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 via-gray-900 to-blue-900/20 opacity-50"></div>
      
      {/* Decorative elements */}
      <div className="absolute bottom-0 left-0 right-0 h-1/2 bg-gradient-to-t from-blue-900/10 to-transparent"></div>
      <div className="absolute top-1/4 left-1/4 w-1/2 h-1/2 bg-gradient-to-br from-blue-900/5 to-purple-900/5 rounded-full blur-3xl"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="text-center mb-16">
          <div className="inline-block bg-blue-600/20 border border-blue-500/30 rounded-lg px-3 py-1 text-sm text-blue-400 font-medium mb-4">
            INTUITIVE VISUALIZATION
          </div>
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Stunning Interface Design</h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Our sleek dashboard gives you all the information you need at a glance, with detailed views just a tap away.
          </p>
        </div>
        
        {/* Vehicle type selector */}
        <div className="mb-12">
          <div className="bg-gray-800/50 rounded-xl p-4 max-w-2xl mx-auto">
            <h3 className="text-center text-lg font-medium mb-4">Choose Your Vehicle Type</h3>
            <div className="grid grid-cols-4 gap-3">
              {['sedan', 'suv', 'sports', 'truck'].map((type) => (
                <button 
                  key={type} 
                  className={`p-3 rounded-lg transition-all duration-300 ${
                    selectedVehicle === type 
                      ? 'bg-blue-600 text-white shadow-lg shadow-blue-600/30' 
                      : 'bg-gray-700/50 text-gray-300 hover:bg-gray-700'
                  }`}
                  onClick={() => setSelectedVehicle(type)}
                >
                  <div className="flex flex-col items-center">
                    <Car className="w-8 h-8 mb-1" />
                    <span className="text-sm capitalize">{type}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-gray-900 rounded-xl overflow-hidden shadow-xl transform transition-all duration-500 hover:scale-105 hover:shadow-2xl hover:shadow-blue-500/10">
            <div className="p-4 bg-gradient-to-r from-blue-900/50 to-blue-800/30 relative">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-600/10 via-transparent to-transparent"></div>
              <h3 className="font-bold text-xl flex items-center gap-2 relative">
                <Gauge className="w-5 h-5" /> Performance Metrics
              </h3>
            </div>
            <div className="p-4">
              <img 
                src="/assets/realtime_analysis.jpg" 
                alt="Real-time Performance Analysis"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>

          <div className="bg-gray-900 rounded-xl overflow-hidden shadow-xl transform transition-all duration-500 hover:scale-105 hover:shadow-2xl hover:shadow-purple-500/10">
            <div className="p-4 bg-gradient-to-r from-purple-900/50 to-purple-800/30 relative">
              <div className="absolute inset-0 bg-gradient-to-r from-purple-600/10 via-transparent to-transparent"></div>
              <h3 className="font-bold text-xl flex items-center gap-2 relative">
                <Battery className="w-5 h-5" /> Vehicle Health
              </h3>
            </div>
            <div className="p-4">
              <img 
                src="/assets/battery_monitor.jpg" 
                alt="Battery and Vehicle Health Monitoring"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>

          <div className="bg-gray-900 rounded-xl overflow-hidden shadow-xl transform transition-all duration-500 hover:scale-105 hover:shadow-2xl hover:shadow-green-500/10">
            <div className="p-4 bg-gradient-to-r from-green-900/50 to-green-800/30 relative">
              <div className="absolute inset-0 bg-gradient-to-r from-green-600/10 via-transparent to-transparent"></div>
              <h3 className="font-bold text-xl flex items-center gap-2 relative">
                <Award className="w-5 h-5" /> Achievements
              </h3>
            </div>
            <div className="p-4">
              <img 
                src="/assets/achievements.jpg" 
                alt="Driver Achievements and Goals"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
        
        {/* Additional Dashboard Views */}
        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-gray-900 rounded-xl overflow-hidden shadow-xl">
            <div className="p-4 bg-gradient-to-r from-blue-900/50 to-cyan-800/30 relative">
              <h3 className="font-bold text-xl flex items-center gap-2 relative">
                <Navigation className="w-5 h-5" /> Trip Analytics
              </h3>
            </div>
            <div className="p-4">
              <img 
                src={`${process.env.PUBLIC_URL}/assets/analytics_dashboard.jpg`} 
                alt="Distance and Trip Analytics"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
          
          <div className="bg-gray-900 rounded-xl overflow-hidden shadow-xl">
            <div className="p-4 bg-gradient-to-r from-orange-900/50 to-red-800/30 relative">
              <h3 className="font-bold text-xl flex items-center gap-2 relative">
                <Wrench className="w-5 h-5" /> Maintenance Tracking
              </h3>
            </div>
            <div className="p-4">
              <img 
                src={`${process.env.PUBLIC_URL}/assets/oil_changes_recording.jpg`} 
                alt="Oil Changes and Maintenance Recording"
                className="w-full h-auto rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
        
        {/* Main Dashboard Preview */}
        <div className="mt-16">
          <div className="text-center mb-8">
            <h3 className="text-2xl font-bold mb-2">Complete Dashboard View</h3>
            <p className="text-gray-400">All your important vehicle information at a glance</p>
          </div>
          
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-blue-600/30 via-purple-600/30 to-blue-600/30 blur-lg opacity-50 rounded-xl"></div>
            <div className="relative rounded-xl overflow-hidden shadow-2xl">
              <img 
                src={`${process.env.PUBLIC_URL}/assets/dashboard.jpg`} 
                alt="Complete Dashboard Interface"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
        
        {/* Customization Preview */}
        <div className="mt-16">
          <div className="text-center mb-8">
            <h3 className="text-2xl font-bold mb-2">Personalized Experience</h3>
            <p className="text-gray-400">Customize your dashboard to match your style and preferences</p>
          </div>
          
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-purple-600/30 via-blue-600/30 to-purple-600/30 blur-lg opacity-50 rounded-xl"></div>
            <div className="relative rounded-xl overflow-hidden shadow-2xl">
              <img 
                src={`${process.env.PUBLIC_URL}/assets/customization_car_plate_color.jpg`} 
                alt="Dashboard Customization Options"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard; 